<template>
  <div>
    <b-row v-if="this.$route.name != 'report'">
      <b-col cols="12" class="mb-1">
        <b-tabs align="center">
          <b-tab
            v-for="(header, index) in filterLabels"
            :key="index"
            :title="header.label"
            @click="switchFilter(header.value)"
          />
        </b-tabs>
      </b-col>
    </b-row>
    <div class="my-1 d-flex justify-content-between align-items-center">
      <b-button
        variant="gradient-primary"
        class="box-shadow-1 px-5"
        :disabled="!this.rows.length"
        @click="DownloadAllDO()"
        >Download All Delivery Order</b-button
      >
      <b-form-group>
        <flat-pickr
          v-model="chosenDate"
          :config="{
            dateFormat: 'Y-m-d',
            defaultDate: 'today',
          }"
          class="form-control font-small-2 w-auto mt-1"
          placeholder="Choose Date"
          readonly="readonly"
          @input="getOrders()"
        />
      </b-form-group>
    </div>
    <b-card>
      <select
        v-model="type"
        class="form-control form-select rounded text-primary border-primary px-2 w-25"
        @change="handleExport(type)"
      >
        <option value="" disabled selected>Export</option>
        <option value="csv">CSV</option>
        <option value="excel">Excel</option>
      </select>
      <MyTable
        :rows="rows"
        :columns="columns"
        :is-loading="isLoading"
        :checkbox-enabled="true"
        @updateOrder="getOrders"
      >
        <template v-slot="{ props }">
          <div v-if="props.column.field === 'id'">
            <b-button
              variant="primary"
              @click="handleModal('order-summary', props.row)"
            >
              {{ props.row.id }}
            </b-button>
          </div>

          <div v-else-if="props.column.field === 'delivery_date'">
            {{ $helpers.formatDate(props.row.delivery_date) }}
          </div>

          <div v-else-if="props.column.field == 'status'">
            <button
              v-if="props.row.status === 'Active'"
              block
              class="btn btn-block Active"
            >
              {{ props.row.status }}
            </button>
            <button
              v-else-if="props.row.status === 'Assigned'"
              block
              class="btn btn-block Assigned"
            >
              {{ props.row.status }}
            </button>
            <button
              v-if="props.row.status === 'In Warehouse'"
              block
              class="btn btn-block InWarehouse"
            >
              {{ props.row.status }}
            </button>
            <button
              v-else-if="props.row.status === 'In Transit'"
              block
              class="btn btn-block InTransit"
            >
              {{ props.row.status }}
            </button>
            <button
              v-if="props.row.status === 'Out For Delivery'"
              block
              class="btn btn-block OutForDelivery"
            >
              {{ props.row.status }}
            </button>
            <button
              v-else-if="props.row.status === 'Delivered'"
              block
              class="btn btn-block Delivered"
            >
              {{ props.row.status }}
            </button>
            <button
              v-else-if="
                props.row.status === 'Close Store' ||
                props.row.status === 'Assigned For Last Mile' ||
                props.row.status === 'Arrived At Branch'
              "
              block
              class="btn btn-block CloseStoreDeliveryFailed"
            >
              {{ props.row.status }}
            </button>
            <button
              v-else-if="props.row.status === 'Second Delivery'"
              block
              class="btn btn-block SecondDelivery"
            >
              {{ props.row.status }}
            </button>
            <b-button
              v-else-if="props.row.status === 'Cancelled'"
              block
              variant="danger"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'Generated'"
              block
              variant="warning"
            >
              Generated
            </b-button>
          </div>

          <span v-else-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="DownloadDO(props.row.id)">
                <span>Download DO</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <div v-else>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </template>
      </MyTable>
    </b-card>
    <b-modal
      ref="modal"
      :title="modal.title"
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <DistributorOrderSummary
        v-if="modal.type === 'order-summary'"
        :order="modal.data"
      />
    </b-modal>
  </div>
</template>

<script>
import MyTable from '@/views/components/Distributortable.vue';
import DistributorOrderSummary from '@/views/pages/inside/Distributor/DistributorOrderSummary.vue';
import flatPickr from 'vue-flatpickr-component';
import exportFromJSON from 'export-from-json';
import csvDownload from 'json-to-csv-export';

export default {
  name: 'IncomingOrders',
  components: {
    MyTable,
    DistributorOrderSummary,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      filterLabels: [
        { label: 'Active', value: 'Active' },
        { label: 'Assigned', value: 'Assigned' },
        { label: 'Scheduled', value: 'Scheduled' },
        { label: 'History', value: 'Delivered' },
        { label: 'Cancelled', value: 'Cancelled' },
        { label: 'Refund Request', value: 'Refund Request' },
        // { label: "Payment Failed", value: "Payment Failed" },
      ],
      filter: 'Active',
      chosenDate: '',
      type: '',
      dummyData: {},
      modal: {},
      rows: [],
      columns: [],
      orderColumns: [
        {
          label: 'Order ID',
          field: 'id',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Order Date',
          field: 'order_date',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Pick Up Date',
          field: 'order_type',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Delivery Date',
          field: 'delivery_date',
          tdClass: 'text-center align-middle',
        },
        {
          label: "Sender's Name",
          field: 'recipient.address_postcode',
          tdClass: 'text-center align-middle',
        },
        {
          label: "Recipient's Name",
          field: 'order_placed_date',
          tdClass: 'text-center align-middle',
        },
        {
          label: "Sender's Address",
          field: 'delivery_date',
          tdClass: 'text-center align-middle',
        },
        {
          label: "Recipient's Address",
          field: 'quantity',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Action',
          field: 'action',
          tdClass: 'text-center align-middle',
        },
      ],
      refundColumns: [
        {
          label: 'Order ID',
          field: 'id',
          tdClass: 'text-center',
        },
        {
          label: 'Delivery Date',
          field: 'delivery_date',
          tdClass: 'text-center',
        },
        {
          label: 'Request Date',
          field: 'date_requested',
          tdClass: 'text-center',
        },
        {
          label: 'Date Refunded',
          field: 'date_of_approval_or_declined',
          tdClass: 'text-center',
        },
        {
          label: 'Postcode Origin',
          field: 'postcode_origin',
          tdClass: 'text-center',
        },
        {
          label: 'Postcode Destination',
          field: 'postcode_destination',
          tdClass: 'text-center',
        },
        {
          label: 'Amount',
          field: 'amount',
          tdClass: 'text-center',
        },
        {
          label: 'Status',
          field: 'refund_status',
          tdClass: 'text-center',
        },
        {
          label: 'Action',
          field: 'action',
          tdClass: 'text-center',
        },
      ],
    };
  },
  created() {
    if (this.$route.query.date) {
      this.chosenDate = this.$route.query.date;
      this.filter = this.$route.query.filter;
    } else {
      this.chosenDate = new Date().toJSON().slice(0, 10);
    }
    this.handlePreload();
  },
  methods: {
    async handlePreload() {
      await this.getOrders();
    },

    switchFilter(filter) {
      this.filter = filter;
      this.getOrders();
    },

    async getOrders() {
      this.columns = [];
      this.rows = [];
      this.isLoading = true;
      if (this.chosenDate.includes('T')) {
        this.chosenDate = this.chosenDate.split('T')[0];
      }
      this.$router
        .replace({ query: { date: this.chosenDate, filter: this.filter } })
        .catch((err) => {});
      const response = await this.$http.get(
        `orders/outlet/${this.chosenDate}/outgoing?filter=${this.filter}`
      );
      if (this.filter != 'Refund Request') {
        this.columns = this.orderColumns;
      } else {
        this.columns = this.refundColumns;
      }
      if (response.status === 200) {
        this.rows = response.data.data;
        this.isLoading = false;
      }
    },

    handleModal(type, data = []) {
      const title = type.replace('-', ' ').toLocaleUpperCase();
      this.modal = {
        title,
        type,
        data,
      };
      this.$refs.modal.show();
    },
    DownloadDO(id) {
      window.open(`${this.$baseURL}/generate_golog_label/${id}`, '_blank');
    },
    async DownloadAllDO() {
      const response = await this.$http.get(
        `bulkGenerateLabel?order_type=Outlet&date=${this.dateFormat}`,
        { responseType: 'blob' }
      );
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute(
        'download',
        `${this.chosenDate}OutletOutgoingOrders.pdf`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    handleExport(type) {
      const data = [];
      for (let x = 0; x < this.rows.length; x++) {
        if (this.rows[x].delivery_date) {
          this.dummyData.DeliveryDate =
            this.rows[x].delivery_date.split(' ')[0];
        } else {
          this.dummyData.DeliveryDate = 'N/A';
        }
        if (this.rows[x].pickup_date) {
          this.dummyData.PickupDate = this.rows[x].pickup_date.split(' ')[0];
        } else {
          this.dummyData.PickupDate = 'N/A';
        }
        if (this.rows[x].sender.last_name) {
          this.dummyData.sendername = `${this.rows[x].sender.first_name} ${this.rows[x].sender.last_name}`;
        } else {
          this.dummyData.sendername = this.rows[x].sender.first_name;
        }
        if (this.rows[x].recipient.last_name) {
          this.dummyData.recipientname = `${this.rows[x].recipient.first_name} ${this.rows[x].recipient.last_name}`;
        } else {
          this.dummyData.recipientname = this.rows[x].recipient.first_name;
        }
        data.push({
          PickupDate: this.dummyData.PickupDate,
          DeliveryDate: this.dummyData.DeliveryDate,
          OrderID: this.rows[x].id,
          RecipientName: this.dummyData.recipientname,
          RecipientContact: this.rows[x].recipient.phone,
          SenderName: this.dummyData.sendername,
          OriginAddress: this.rows[x].sender.complete_address,
          Postcode: this.rows[x].recipient.address_postcode,
          DestinationAddress: this.rows[x].recipient.complete_address,
          Remark: this.rows[x].remarks,
          Status: this.rows[x].status,
          Quantity: this.rows[x].quantity,
          Amount: this.$helpers.formatTotal(this.rows[x].amount),
        });
      }
      const fileName = `${this.rows[0].order_type}_${this.dummyData.DeliveryDate}`;
      switch (type) {
        case 'excel':
          var exportType = exportFromJSON.types.xls;
          exportFromJSON({ data, fileName, exportType });
          break;
        case 'csv':
          const dataToConvert = {
            data,
            filename: `${this.rows[0].order_type}_${this.dummyData.DeliveryDate}`,
            delimiter: ',',
            headers: [
              'PickupDate',
              'DeliveryDate',
              'OrderID',
              'RecipientName',
              'RecipientContact',
              'SenderName',
              'OriginAddress',
              'Postcode',
              'DestinationAddress',
              'Remark',
              'Status',
              'Quantity',
              'Amount',
            ],
          };
          csvDownload(dataToConvert);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.Active {
  background-color: #6558f5;
  color: white;
}

.Assigned {
  background-color: #730fc3;
  color: white;
}

.InWarehouse {
  background-color: #2c88d9;
  color: white;
}

.InTransit {
  background-color: #f7c325;
  color: white;
}

.OutForDelivery {
  background-color: #bd34d1;
  color: white;
}

.Delivered {
  background-color: #1aae9f;
  color: white;
}

.CloseStoreDeliveryFailed {
  background-color: #d3455b;
  color: white;
}

.SecondDelivery {
  background-color: #e8833a;
  color: white;
}
</style>
